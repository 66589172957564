import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'gatsby';

import theme from '../stylesheets/theme';

import Layout from '../components/layout';

const PageNotFoundStyles = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2vw;
  .not-found-title {
    text-align: center;
  }
  .not-found-text {
    margin-top: 10px;
    max-width: 400px;
    text-align: center;
  }
  .link-to-home {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const PageNotFound = () => (
  <Layout
    seo={{
      title: '404: Not found'
    }}
  >
    <ThemeProvider theme={theme}>
      <PageNotFoundStyles>
        <h3 className="not-found-title">Olet eksynyt nyt polulta.</h3>
        <p className="not-found-text">
          Mutta ei hätää,{' '}
          <Link className="link-to-home" to="/">
            paina tästä
          </Link>{' '}
          ja olet takaisin merkatulla polulla.
        </p>
      </PageNotFoundStyles>
    </ThemeProvider>
  </Layout>
);

export default PageNotFound;
